import React from 'react';

const FacebookIcon = ({ width }: { width: number }) => (
  <svg
    width={width}
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M68.026 0H3.974C2.92003 0 1.90923 0.418688 1.16396 1.16396C0.418688 1.90923 0 2.92003 0 3.974V68.027C0.000265173 69.0808 0.419071 70.0913 1.16431 70.8364C1.90955 71.5814 2.9202 72 3.974 72H38.457V44.118H29.074V33.252H38.457V25.238C38.457 15.938 44.137 10.875 52.433 10.875C56.407 10.875 59.822 11.17 60.818 11.303V21.022L55.064 21.025C50.552 21.025 49.679 23.169 49.679 26.315V33.253H60.439L59.038 44.119H49.679V72H68.027C69.0808 71.9997 70.0913 71.5809 70.8364 70.8357C71.5814 70.0904 72 69.0798 72 68.026V3.974C72 2.92003 71.5813 1.90923 70.836 1.16396C70.0908 0.418688 69.08 0 68.026 0V0Z"
      fill="black"
    />
  </svg>
);

export default FacebookIcon;
