import React from 'react';

const AppStore = ({ width }: { width: number }) => (
  <svg
    viewBox="0 0 236 68"
    fill="none"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M234.078 60.1584C234.078 63.7822 231.081 66.7175 227.375 66.7175H8.01976C4.31515 66.7175 1.30811 63.7822 1.30811 60.1584V7.85118C1.30811 4.22908 4.31515 1.28345 8.01976 1.28345H227.373C231.081 1.28345 234.076 4.22908 234.076 7.85118L234.078 60.1584Z"
      fill="black"
    />
    <path
      d="M226.667 1.36224C230.704 1.36224 233.988 4.56462 233.988 8.50013V59.5002C233.988 63.4357 230.704 66.6381 226.667 66.6381H8.71797C4.68155 66.6381 1.39705 63.4357 1.39705 59.5002V8.50013C1.39705 4.56462 4.68155 1.36224 8.71797 1.36224H226.667ZM226.667 0.000115458H8.71797C3.92526 0.000115458 0 3.82725 0 8.50013V59.5002C0 64.1731 3.92526 68.0002 8.71797 68.0002H226.667C231.46 68.0002 235.385 64.1731 235.385 59.5002V8.50013C235.385 3.82725 231.46 0.000115458 226.667 0.000115458Z"
      fill="#A6A6A6"
    />
    <path
      d="M52.5318 33.633C52.4812 28.1539 57.1331 25.4883 57.3458 25.3642C54.7113 21.6191 50.6278 21.1074 49.1928 21.0666C45.7632 20.7147 42.4364 23.0675 40.6893 23.0675C38.9074 23.0675 36.217 21.1006 33.3174 21.1584C29.5861 21.2145 26.0954 23.3208 24.181 26.5916C20.23 33.2607 23.1767 43.0613 26.962 48.452C28.8555 51.0921 31.0682 54.0399 33.9643 53.9362C36.7976 53.8223 37.856 52.175 41.2752 52.175C44.663 52.175 45.6568 53.9362 48.6104 53.8699C51.6513 53.8223 53.5657 51.2179 55.393 48.554C57.5812 45.528 58.46 42.5479 58.4949 42.3949C58.4234 42.3711 52.5893 40.2001 52.5318 33.633Z"
      fill="white"
    />
    <path
      d="M46.9523 17.5204C48.4762 15.6623 49.5189 13.1344 49.2294 10.5691C47.0238 10.6643 44.2654 12.0566 42.677 13.8739C41.2717 15.4753 40.0163 18.1001 40.3406 20.5685C42.8182 20.7487 45.3621 19.3496 46.9523 17.5204Z"
      fill="white"
    />
    <path
      d="M93.5346 53.5572H89.5749L87.4058 46.9119H79.8666L77.8004 53.5572H73.9453L81.4149 30.9336H86.0284L93.5346 53.5572ZM86.752 44.1239L84.7905 38.2164C84.583 37.6129 84.1941 36.1917 83.6205 33.9545H83.5508C83.3224 34.9167 82.9545 36.3379 82.4488 38.2164L80.5221 44.1239H86.752V44.1239Z"
      fill="white"
    />
    <path
      d="M112.744 45.2001C112.744 47.9745 111.975 50.1675 110.438 51.7774C109.06 53.2105 107.35 53.9262 105.308 53.9262C103.104 53.9262 101.521 53.1544 100.557 51.6108H100.487V60.2043H96.7696V42.6144C96.7696 40.8702 96.7225 39.0801 96.6318 37.2441H99.9011L100.109 39.8298H100.178C101.418 37.8816 103.299 36.9092 105.824 36.9092C107.798 36.9092 109.446 37.6691 110.764 39.1906C112.085 40.7138 112.744 42.7164 112.744 45.2001ZM108.957 45.3327C108.957 43.7449 108.591 42.4359 107.855 41.4057C107.052 40.3313 105.972 39.7941 104.619 39.7941C103.702 39.7941 102.869 40.0933 102.124 40.6832C101.378 41.2782 100.89 42.0551 100.661 43.0173C100.546 43.4661 100.489 43.8333 100.489 44.1223V46.8423C100.489 48.0289 100.862 49.0302 101.608 49.8479C102.354 50.6656 103.324 51.0736 104.516 51.0736C105.916 51.0736 107.006 50.5466 107.786 49.496C108.567 48.4437 108.957 47.0565 108.957 45.3327Z"
      fill="white"
    />
    <path
      d="M131.988 45.2001C131.988 47.9745 131.219 50.1675 129.68 51.7774C128.304 53.2105 126.594 53.9262 124.552 53.9262C122.348 53.9262 120.765 53.1544 119.802 51.6108H119.733V60.2043H116.015V42.6144C116.015 40.8702 115.968 39.0801 115.877 37.2441H119.147L119.354 39.8298H119.424C120.662 37.8816 122.543 36.9092 125.07 36.9092C127.042 36.9092 128.689 37.6691 130.011 39.1906C131.327 40.7138 131.988 42.7164 131.988 45.2001ZM128.201 45.3327C128.201 43.7449 127.833 42.4359 127.097 41.4057C126.294 40.3313 125.218 39.7941 123.863 39.7941C122.944 39.7941 122.113 40.0933 121.366 40.6832C120.62 41.2782 120.134 42.0551 119.905 43.0173C119.792 43.4661 119.733 43.8333 119.733 44.1223V46.8423C119.733 48.0289 120.106 49.0302 120.848 49.8479C121.595 50.6639 122.564 51.0736 123.76 51.0736C125.16 51.0736 126.25 50.5466 127.029 49.496C127.811 48.4437 128.201 47.0565 128.201 45.3327Z"
      fill="white"
    />
    <path
      d="M153.504 47.2128C153.504 49.1372 152.818 50.7029 151.443 51.9116C149.931 53.2325 147.826 53.8921 145.122 53.8921C142.625 53.8921 140.624 53.4229 139.108 52.4828L139.97 49.4619C141.602 50.4241 143.392 50.9069 145.343 50.9069C146.744 50.9069 147.833 50.5975 148.616 49.9821C149.396 49.3667 149.784 48.5405 149.784 47.5103C149.784 46.5923 149.464 45.8188 148.82 45.1915C148.18 44.5642 147.111 43.9811 145.619 43.4422C141.556 41.9649 139.527 39.8008 139.527 36.955C139.527 35.0952 140.238 33.5703 141.663 32.3837C143.082 31.1954 144.976 30.6021 147.343 30.6021C149.455 30.6021 151.209 30.9608 152.609 31.6765L151.68 34.6311C150.372 33.9375 148.893 33.5907 147.239 33.5907C145.931 33.5907 144.909 33.9052 144.177 34.5308C143.558 35.0901 143.248 35.7718 143.248 36.5793C143.248 37.4735 143.602 38.213 144.313 38.7944C144.932 39.3316 146.057 39.913 147.689 40.5403C149.685 41.324 151.151 42.2403 152.095 43.2909C153.034 44.3381 153.504 45.6488 153.504 47.2128Z"
      fill="white"
    />
    <path
      d="M165.795 39.9639H161.697V47.8842C161.697 49.8987 162.419 50.9051 163.866 50.9051C164.53 50.9051 165.081 50.849 165.517 50.7368L165.62 53.4891C164.888 53.756 163.924 53.8903 162.729 53.8903C161.261 53.8903 160.114 53.4534 159.286 52.5813C158.461 51.7075 158.046 50.2421 158.046 48.1834V39.9605H155.605V37.2405H158.046V34.2536L161.697 33.1792V37.2405H165.795V39.9639Z"
      fill="white"
    />
    <path
      d="M184.282 45.2662C184.282 47.7737 183.546 49.8324 182.078 51.4423C180.538 53.0998 178.495 53.926 175.947 53.926C173.492 53.926 171.538 53.1321 170.08 51.5443C168.622 49.9565 167.894 47.9522 167.894 45.5365C167.894 43.0086 168.643 40.938 170.148 39.3281C171.649 37.7164 173.675 36.9106 176.223 36.9106C178.678 36.9106 180.653 37.7045 182.144 39.294C183.57 40.836 184.282 42.8267 184.282 45.2662ZM180.425 45.3835C180.425 43.879 180.095 42.5887 179.427 41.5126C178.648 40.2104 177.534 39.5609 176.09 39.5609C174.596 39.5609 173.461 40.2121 172.681 41.5126C172.014 42.5904 171.684 43.9011 171.684 45.4515C171.684 46.956 172.014 48.2463 172.681 49.3207C173.485 50.6229 174.608 51.2723 176.057 51.2723C177.476 51.2723 178.59 50.6093 179.394 49.2867C180.08 48.1902 180.425 46.8863 180.425 45.3835Z"
      fill="white"
    />
    <path
      d="M196.365 40.4316C195.997 40.3653 195.605 40.3313 195.193 40.3313C193.885 40.3313 192.874 40.8124 192.163 41.7763C191.544 42.6263 191.233 43.7007 191.233 44.9978V53.5573H187.518L187.553 42.3815C187.553 40.5013 187.505 38.7894 187.413 37.2458H190.651L190.787 40.367H190.89C191.282 39.2943 191.901 38.4307 192.749 37.783C193.577 37.1999 194.471 36.9092 195.435 36.9092C195.779 36.9092 196.089 36.933 196.365 36.9755V40.4316Z"
      fill="white"
    />
    <path
      d="M212.99 44.6287C212.99 45.2781 212.947 45.8255 212.854 46.2726H201.702C201.746 47.8842 202.285 49.1167 203.32 49.9667C204.26 50.7266 205.476 51.1074 206.968 51.1074C208.619 51.1074 210.126 50.8507 211.48 50.3356L212.063 52.8516C210.48 53.5248 208.611 53.8597 206.454 53.8597C203.859 53.8597 201.823 53.1151 200.341 51.6276C198.862 50.1401 198.121 48.1426 198.121 45.6368C198.121 43.1769 198.81 41.1284 200.189 39.4947C201.633 37.7505 203.584 36.8784 206.039 36.8784C208.45 36.8784 210.276 37.7505 211.515 39.4947C212.497 40.8802 212.99 42.5938 212.99 44.6287ZM209.446 43.6886C209.47 42.6142 209.228 41.686 208.724 40.9023C208.08 39.8942 207.092 39.391 205.762 39.391C204.546 39.391 203.558 39.8823 202.803 40.8683C202.184 41.652 201.816 42.5921 201.702 43.6869H209.446V43.6886Z"
      fill="white"
    />
    <path
      d="M85.5234 17.016C85.5234 19.0169 84.9079 20.5231 83.6787 21.5346C82.5401 22.4679 80.9221 22.9354 78.8263 22.9354C77.7871 22.9354 76.8978 22.8912 76.1533 22.8028V11.8701C77.1245 11.7171 78.1707 11.6389 79.3005 11.6389C81.2969 11.6389 82.8016 12.0622 83.8164 12.9088C84.9532 13.8659 85.5234 15.2344 85.5234 17.016ZM83.5967 17.0653C83.5967 15.7682 83.2445 14.7737 82.5401 14.0801C81.8357 13.3882 80.807 13.0414 79.4522 13.0414C78.8768 13.0414 78.3869 13.0788 77.9806 13.157V21.4683C78.2055 21.5023 78.617 21.5176 79.2151 21.5176C80.6134 21.5176 81.6927 21.1385 82.4529 20.3803C83.2131 19.6221 83.5967 18.5171 83.5967 17.0653Z"
      fill="white"
    />
    <path
      d="M95.7391 18.7637C95.7391 19.9962 95.3782 21.006 94.6564 21.7982C93.8996 22.6125 92.8971 23.0188 91.6452 23.0188C90.4386 23.0188 89.4779 22.6295 88.7613 21.8475C88.0464 21.0672 87.689 20.0829 87.689 18.8963C87.689 17.6553 88.0569 16.637 88.7961 15.8465C89.5354 15.056 90.5293 14.6599 91.7812 14.6599C92.9877 14.6599 93.9572 15.0492 94.6912 15.8295C95.3887 16.5877 95.7391 17.5669 95.7391 18.7637ZM93.8439 18.8215C93.8439 18.082 93.68 17.4479 93.3539 16.9192C92.9703 16.28 92.4246 15.9604 91.7149 15.9604C90.9809 15.9604 90.4229 16.28 90.0393 16.9192C89.7115 17.4479 89.5494 18.0922 89.5494 18.8538C89.5494 19.5933 89.7133 20.2274 90.0393 20.7561C90.4351 21.3953 90.9861 21.7149 91.6975 21.7149C92.3949 21.7149 92.9424 21.3902 93.3365 20.7391C93.6747 20.2002 93.8439 19.561 93.8439 18.8215Z"
      fill="white"
    />
    <path
      d="M109.437 14.8235L106.865 22.8373H105.191L104.126 19.3574C103.855 18.4887 103.636 17.6251 103.465 16.7683H103.432C103.273 17.6489 103.053 18.5108 102.771 19.3574L101.639 22.8373H99.9462L97.5278 14.8235H99.4057L100.335 18.6332C100.56 19.5342 100.745 20.3927 100.893 21.2053H100.926C101.062 20.5355 101.287 19.6821 101.604 18.6502L102.771 14.8252H104.26L105.377 18.5686C105.648 19.4815 105.867 20.3604 106.037 21.207H106.087C106.211 20.3825 106.397 19.5036 106.645 18.5686L107.642 14.8252H109.437V14.8235Z"
      fill="white"
    />
    <path
      d="M118.91 22.8369H117.082V18.2469C117.082 16.8325 116.531 16.1253 115.426 16.1253C114.884 16.1253 114.446 16.3191 114.106 16.7084C113.769 17.0977 113.599 17.5567 113.599 18.082V22.8352H111.771V17.113C111.771 16.4092 111.749 15.6459 111.705 14.8197H113.311L113.396 16.0726H113.447C113.66 15.6833 113.977 15.362 114.394 15.1053C114.889 14.8061 115.443 14.6548 116.05 14.6548C116.817 14.6548 117.455 14.8962 117.963 15.3807C118.594 15.974 118.91 16.8597 118.91 18.0361V22.8369V22.8369Z"
      fill="white"
    />
    <path d="M123.949 22.8369H122.123V11.146H123.949V22.8369Z" fill="white" />
    <path
      d="M134.706 18.7637C134.706 19.9962 134.345 21.006 133.624 21.7982C132.867 22.6125 131.863 23.0188 130.612 23.0188C129.404 23.0188 128.443 22.6295 127.729 21.8475C127.014 21.0672 126.656 20.0829 126.656 18.8963C126.656 17.6553 127.024 16.637 127.763 15.8465C128.503 15.056 129.497 14.6599 130.747 14.6599C131.955 14.6599 132.923 15.0492 133.659 15.8295C134.356 16.5877 134.706 17.5669 134.706 18.7637ZM132.809 18.8215C132.809 18.082 132.645 17.4479 132.319 16.9192C131.938 16.28 131.39 15.9604 130.682 15.9604C129.946 15.9604 129.388 16.28 129.007 16.9192C128.679 17.4479 128.517 18.0922 128.517 18.8538C128.517 19.5933 128.681 20.2274 129.007 20.7561C129.402 21.3953 129.953 21.7149 130.665 21.7149C131.362 21.7149 131.908 21.3902 132.302 20.7391C132.642 20.2002 132.809 19.561 132.809 18.8215Z"
      fill="white"
    />
    <path
      d="M143.55 22.8369H141.91L141.774 21.9138H141.723C141.162 22.6499 140.361 23.0188 139.322 23.0188C138.546 23.0188 137.919 22.7757 137.446 22.2929C137.017 21.8543 136.803 21.3086 136.803 20.6609C136.803 19.6817 137.221 18.9354 138.063 18.4186C138.904 17.9018 140.086 17.6485 141.608 17.6604V17.5108C141.608 16.4551 141.04 15.9281 139.901 15.9281C139.09 15.9281 138.375 16.127 137.758 16.5214L137.387 15.3518C138.151 14.8911 139.094 14.6599 140.206 14.6599C142.354 14.6599 143.432 15.7649 143.432 17.9749V20.9261C143.432 21.7268 143.472 22.3643 143.55 22.8369ZM141.653 20.0829V18.847C139.638 18.813 138.63 19.3519 138.63 20.462C138.63 20.8802 138.745 21.193 138.98 21.4021C139.216 21.6112 139.516 21.7149 139.873 21.7149C140.274 21.7149 140.649 21.5908 140.991 21.3443C141.334 21.0961 141.545 20.7816 141.624 20.3957C141.643 20.309 141.653 20.2036 141.653 20.0829Z"
      fill="white"
    />
    <path
      d="M153.933 22.8369H152.312L152.226 21.55H152.176C151.658 22.5292 150.775 23.0188 149.536 23.0188C148.545 23.0188 147.721 22.6397 147.067 21.8815C146.413 21.1233 146.087 20.139 146.087 18.9303C146.087 17.6332 146.441 16.5826 147.152 15.7802C147.841 15.0322 148.685 14.6582 149.689 14.6582C150.793 14.6582 151.565 15.0203 152.005 15.7462H152.04V11.146H153.869V20.6779C153.869 21.4582 153.889 22.1773 153.933 22.8369ZM152.04 19.4573V18.1211C152.04 17.8899 152.022 17.7029 151.989 17.5601C151.886 17.1317 151.665 16.7713 151.328 16.4806C150.988 16.1899 150.578 16.0437 150.106 16.0437C149.424 16.0437 148.891 16.3072 148.498 16.8359C148.109 17.3646 147.912 18.0395 147.912 18.864C147.912 19.6562 148.099 20.2988 148.474 20.7935C148.87 21.3205 149.403 21.584 150.071 21.584C150.671 21.584 151.15 21.3647 151.515 20.9244C151.867 20.5181 152.04 20.0285 152.04 19.4573Z"
      fill="white"
    />
    <path
      d="M169.561 18.7637C169.561 19.9962 169.2 21.006 168.479 21.7982C167.722 22.6125 166.721 23.0188 165.467 23.0188C164.263 23.0188 163.302 22.6295 162.584 21.8475C161.869 21.0672 161.511 20.0829 161.511 18.8963C161.511 17.6553 161.879 16.637 162.618 15.8465C163.358 15.056 164.352 14.6599 165.605 14.6599C166.81 14.6599 167.781 15.0492 168.514 15.8295C169.211 16.5877 169.561 17.5669 169.561 18.7637ZM167.668 18.8215C167.668 18.082 167.504 17.4479 167.178 16.9192C166.793 16.28 166.249 15.9604 165.537 15.9604C164.805 15.9604 164.247 16.28 163.862 16.9192C163.534 17.4479 163.372 18.0922 163.372 18.8538C163.372 19.5933 163.536 20.2274 163.862 20.7561C164.257 21.3953 164.808 21.7149 165.52 21.7149C166.217 21.7149 166.766 21.3902 167.16 20.7391C167.497 20.2002 167.668 19.561 167.668 18.8215Z"
      fill="white"
    />
    <path
      d="M179.386 22.8369H177.56V18.2469C177.56 16.8325 177.009 16.1253 175.902 16.1253C175.36 16.1253 174.922 16.3191 174.584 16.7084C174.246 17.0977 174.077 17.5567 174.077 18.082V22.8352H172.248V17.113C172.248 16.4092 172.227 15.6459 172.183 14.8197H173.787L173.873 16.0726H173.923C174.138 15.6833 174.455 15.362 174.87 15.1053C175.367 14.8061 175.92 14.6548 176.528 14.6548C177.294 14.6548 177.932 14.8962 178.439 15.3807C179.072 15.974 179.386 16.8597 179.386 18.0361V22.8369V22.8369Z"
      fill="white"
    />
    <path
      d="M191.683 16.1579H189.671V20.051C189.671 21.0404 190.028 21.5351 190.736 21.5351C191.064 21.5351 191.336 21.5079 191.551 21.4518L191.598 22.8033C191.237 22.9359 190.762 23.0022 190.178 23.0022C189.457 23.0022 188.895 22.788 188.489 22.3596C188.081 21.9312 187.879 21.2104 187.879 20.1989V16.1579H186.677V14.8234H187.879V13.3546L189.669 12.8276V14.8217H191.681V16.1579H191.683Z"
      fill="white"
    />
    <path
      d="M201.357 22.8369H199.528V18.2809C199.528 16.8444 198.977 16.1253 197.873 16.1253C197.026 16.1253 196.447 16.5418 196.13 17.3748C196.076 17.5499 196.044 17.7641 196.044 18.0157V22.8352H194.219V11.146H196.044V15.9757H196.079C196.655 15.0968 197.479 14.6582 198.548 14.6582C199.305 14.6582 199.931 14.8996 200.428 15.3841C201.047 15.9876 201.357 16.8852 201.357 18.0718V22.8369V22.8369Z"
      fill="white"
    />
    <path
      d="M211.336 18.4511C211.336 18.7707 211.312 19.0393 211.268 19.2586H205.788C205.813 20.0508 206.074 20.6543 206.581 21.0725C207.045 21.4465 207.643 21.6335 208.376 21.6335C209.186 21.6335 209.926 21.5077 210.592 21.2544L210.878 22.492C210.098 22.8218 209.181 22.9867 208.119 22.9867C206.846 22.9867 205.844 22.6212 205.119 21.8902C204.39 21.1592 204.029 20.1783 204.029 18.9475C204.029 17.7388 204.365 16.7324 205.044 15.93C205.751 15.0732 206.709 14.6448 207.917 14.6448C209.099 14.6448 209.997 15.0732 210.604 15.93C211.094 16.61 211.336 17.4515 211.336 18.4511ZM209.593 17.9904C209.607 17.4617 209.486 17.0061 209.239 16.6219C208.921 16.1272 208.438 15.879 207.785 15.879C207.188 15.879 206.702 16.1204 206.33 16.6049C206.027 16.9908 205.847 17.4515 205.788 17.9904H209.593Z"
      fill="white"
    />
  </svg>
);

export default AppStore;
