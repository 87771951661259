import React from 'react';
import styled from 'styled-components';

import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Wrapper from '@/components/Wrapper';
import { breakpoints } from '@/components/utils';

import GooglePlay from '@/graphics/GooglePlay';
import AppStore from '@/graphics/AppStore';
import BuyMeCoffee from '@/graphics/BuyMeCoffee';
import InstagramIcon from '@/graphics/InstagramIcon';
import FacebookIcon from '@/graphics/FacebookIcon';

import landing from '@/assets/landing.png';
import gridDesktop from '@/assets/grid-desktop.png';
import gridMobile from '@/assets/grid-mobile.png';

import { appStore, googlePlay } from '@/const';

const IntroSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const IntroBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1;

  margin-top: 70px;
  ${breakpoints('margin-top', [{ 1200: '50px' }, { 1000: '0' }])};
`;

const Cover = styled.div`
  flex: 1;
  ${breakpoints('flex-basis', [{ 700: '100%' }])};
`;

const CoverImage = styled.img`
  display: block;
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 0 10px;
  box-sizing: border-box;
`;

const IntroText = styled.h1`
  font-weight: 300;
  margin: 20px 0;

  font-size: 60px;
  ${breakpoints('font-size', [{ 1200: '50px' }, { 800: '40px' }])};
`;

const StoreButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  ${breakpoints('margin-top', [{ 900: '0' }])};
`;

const StoreButton = styled.div`
  margin: 0 10px 10px 0;
`;

// const Disabled = styled.div`
//   opacity: 0.2;
// `;

// const Subtext = styled.span`
//   opacity: 0.5;
//   font-size: 0.8rem;
//   text-align: right;
//   margin-left: 2px;
// `;

const GridDesktop = styled.img`
  max-width: 100%;
  border-radius: 20px;
  ${breakpoints('display', [{ 600: 'none' }])};
`;

const GridMobile = styled.img`
  max-width: 100%;
  display: none;
  border-radius: 20px;
  ${breakpoints('display', [{ 600: 'initial' }])};
`;

const IconRow = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.span`
  margin: 0 10px;
`;

// const CoffeeText = styled.p`
//   font-weight: 300;
//   text-align: center;
// `;

const Home = () => (
  <main>
    <Header />
    <Wrapper>
      <IntroSection>
        <IntroBox>
          <IntroText>
            Talk to your future self, loved ones and friends
          </IntroText>
          <StoreButtons>
            <StoreButton>
              <a target="_blank" href={googlePlay} rel="noreferrer">
                <GooglePlay width={200} />
              </a>
            </StoreButton>
            <StoreButton>
              <a target="_blank" href={appStore} rel="noreferrer">
                <AppStore width={200} />
              </a>
            </StoreButton>
          </StoreButtons>
        </IntroBox>
        <Cover>
          <CoverImage src={landing} alt="landing" />
        </Cover>
      </IntroSection>
    </Wrapper>
    <Wrapper style={{ marginTop: '20px' }}>
      <GridDesktop src={gridDesktop} />
      <GridMobile src={gridMobile} />
    </Wrapper>
    <Wrapper
      style={{ marginTop: '60px', fontWeight: '300', textAlign: 'center' }}
    >
      <p style={{ marginBottom: '30px' }}>
        We turn coffee into features.
        <br /> If you like our work,
        <br /> consider supporting us.
      </p>
      <a
        target="_blank"
        href="https://www.buymeacoffee.com/capsule"
        rel="noreferrer"
      >
        <BuyMeCoffee width={200} />
      </a>
      <h1
        id="upcoming-features"
        style={{ fontWeight: '300', marginTop: '40px' }}
      >
        Upcoming features:
      </h1>
      <div style={{ textAlign: 'left', display: 'inline-block' }}>
        <ul style={{ marginTop: '0' }}>
          <li>
            <strong>E2E Encryption</strong>
          </li>
          <li>Unschedule messages</li>
          <li>Group chats</li>
          <li>View upcoming scheduled messages</li>
          <li>Image, video and audio messages</li>
          <li>Languages</li>
          <li>... and much more</li>
        </ul>
      </div>
    </Wrapper>
    <Wrapper>
      <IconRow>
        <IconContainer>
          <a
            target="_blank"
            href="https://www.facebook.com/capsuleapp"
            rel="noreferrer"
          >
            <FacebookIcon width={24} />
          </a>
        </IconContainer>
        <IconContainer>
          <a
            target="_blank"
            href="https://www.instagram.com/capsulechat/"
            rel="noreferrer"
          >
            <InstagramIcon width={24} />
          </a>
        </IconContainer>
      </IconRow>
    </Wrapper>
    <Wrapper
      style={{
        marginTop: '20px',
        marginBottom: '20px',
        fontWeight: '300',
        textAlign: 'center',
      }}
    >
      <h1 id="upcoming-features" style={{ fontWeight: '300' }}>
        How it was made:
      </h1>
      <a
        target="_blank"
        href="https://medium.com/@najibghadri/capsule-making-a-chat-app-with-flutter-and-firebase-for-ios-and-android-5c5ef07d1bb7"
        rel="noreferrer"
      >
        https://medium.com/@najibghadri/capsule-making-a-chat-app-with-flutter-and-firebase-for-ios-and-android-5c5ef07d1bb7
      </a>
    </Wrapper>
    <Footer />
  </main>
);

export default Home;
